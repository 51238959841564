import { useEffect, useState } from "react";
import Input from "./components/Input";
import Selector from "./components/Selector";
import TextBox from "./components/TextBox";
import { query } from "./mocks/queryMock";
import { textBoxes } from "./mocks/textboxMock";
import { titleArr } from "./mocks/titleMock";
import { langDictionary } from "./mocks/langMock";
import { getPost, getCountries, getOpenAI, PostNews } from "./services/api";
import { useParams } from "react-router-dom";

function App() {
  const [state, setState] = useState(null);
  const [countries, setCountries] = useState([]);
  const { nid } = useParams();

  useEffect(() => {
    async function startMe() {
      const post = await getPost(nid);
      const tt = await getOpenAI(post.text, langDictionary[post.language], 1);
      let cc = await getCountries();
      post.editedText = tt;
      setState(post);
      setCountries(cc);
    }
    if (nid) startMe();
  }, [nid]);

  function handleSubmit(e) {
    e.preventDefault();
    const rez = PostNews(state);
  }

  function handleChange(e) {
    if (e.target.id === "countries") {
      let options = e.target.options;
      let val = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          val.push(options[i].value);
        }
      }
      setState((state) => ({ ...state, [e.target.id]: [...val] }));
    } else {
      setState((state) => ({ ...state, [e.target.id]: e.target.value }));
    }
  }

  if (!state || !countries.length) return;

  return (
    <div className="w-full flex flex-col">
      <div className="bg-blue-500 text-white flex w-full justify-between px-6 py-4">
        <div>
          <a href="/admin" className="text-2xl text-yellow-200 ml-4">
            Energy News Admin Dashboard
          </a>
        </div>
        <div className="flex items-center">
          <a href="logout" className="p-2">
            Log Out
          </a>
        </div>
      </div>
      <div className="flex w-full bg-blue-300 text-white justify-start py-3 px-6">
        <div>
          <a href="/admin" className="inline ml-4 underline">
            Home
          </a>
          <span className="px-1"> > </span>
          <a href="/admin/posts/" className="inline underline">
            Posts
          </a>
          <span className="px-1"> > </span>
          <a href="/admin/posts/post/" className="inline underline">
            News
          </a>
        </div>
      </div>
      <div className="m-6">
        <p className="text-gray-500 text-2xl px-4">Edit Post</p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Selector
            value={["Manual", "Non Commodity"]}
            text={["Manual", "Non Commodity"]}
            forID={"data_source"}
            handleChange={handleChange}
            selected={state?.data_source}
            title={"Data source:"}
          />
          <Selector
            value={query}
            text={query}
            handleChange={handleChange}
            selected={state?.query}
            forID={"query"}
            title={"Query:"}
          />
          <Input title={"Url:"} forID={"url"} val={state?.url} />
          <Input
            title={"Posted:"}
            handleChange={handleChange}
            forID={"posted"}
            val={state?.posted}
          />
          {textBoxes.map((i) => (
            <TextBox
              title={i.title}
              forID={i.forID}
              key={i.forID}
              trans={i.trans}
              handleChange={handleChange}
              val={state?.[i.val] || state?.editedText}
              para={i.para}
            />
          ))}

          <Selector
            value={countries.map((i) => Object.keys(i)[0])}
            text={countries.map((i) => Object.values(i)[0])}
            handleChange={handleChange}
            selected={state?.countries.map((i) => i.code)}
            forID={"countries"}
            title={"Countries:"}
          />
          {titleArr.map((i) => (
            <Input
              title={i.title}
              forID={i.forID}
              key={i.forID}
              extra={i.extra}
              trans={i.lang}
              handleChange={handleChange}
              val={state?.[i.val] || state?.title || ""}
            />
          ))}
          <Input
            title={"FotoURL:"}
            forID={"fotourl"}
            handleChange={handleChange}
            val={state?.fotourl}
          />
          <div className="flex justify-center">
            <button className="custombtn mt-5" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
