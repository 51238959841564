import { useEffect, useRef, useState } from "react";
import { getOpenAI } from "../services/api";

const Input = ({
  title,
  forID,
  val,
  handleChange,
  extra = false,
  trans = "",
}) => {
  const [local, setLocal] = useState(val);
  const inputRef = useRef(null);

  useEffect(() => {
    async function startMe() {
      if (extra) {
        const input = inputRef.current;
        let response = await getOpenAI(val, trans);
        handleChange({ target: { id: input.id, value: response } });
        setLocal(response);
      } else setLocal(val);
    }
    startMe();
  }, [trans, val, extra]);

  function localChange(e) {
    setLocal(e.target.value);
    handleChange(e);
  }

  async function handleParaphrase(ref) {
    let text = local;
    let res = await getOpenAI(text, trans, 1);
    const input = inputRef.current;
    handleChange({ target: { id: input.id, value: input.value } });
    setLocal(res);
  }

  return (
    <div className="customFlex">
      <div className="flex items-center w-full">
        <div className="labelDiv">
          <label htmlFor={forID}>{title}</label>
          {extra ? (
            <button
              className="block custombtn"
              type="button"
              onClick={() => handleParaphrase(inputRef)}
            >
              Paraphrase
            </button>
          ) : null}
        </div>
        <div className="inputDiv">
          <input
            type="text"
            id={forID}
            ref={inputRef}
            name={forID}
            className="customInput"
            value={local}
            onChange={(e) => localChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
