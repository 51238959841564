import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthWrapper from "./core/AuthWrapper";
import Login from "./core/Login";
import Logout from "./core/Logout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router basename="/newpostedit">
    <Routes>
      <Route
        path={"/:nid"}
        element={
          <AuthWrapper>
            <App />
          </AuthWrapper>
        }
      />
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
    </Routes>
  </Router>
);
