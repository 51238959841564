export const getPost = async (id) => {
  const response = await fetch(
    `https://news.energysalesdirect.com/api/v1/posts/${id}/`
  );
  const result = await response.json();
  return result;
};

export const getCountries = async () => {
  const response = await fetch(
    "https://news.energysalesdirect.com/api/v1/countries/posts/"
  );
  const result = await response.json();
  return result.countries;
};

export const PostNews = async (data) => {
  delete data.source;
  await fetch("https://news.energysalesdirect.com/api/v1/cockpit/emp_news/", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then((tt) => tt);
};

export async function EmpLogin(obj) {
  return await fetch(
    "https://www.energymarketprice.com/siteapi/Administration/account/token",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }
  )
    .then((r) => r.json())
    .then((data) => data);
}

export async function ValidateToken(obj) {
  return await fetch(
    "https://cockpit.energymarketprice.com/api/Auth/TokenValidation/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }
  )
    .then((r) => r.json())
    .then((d) => d);
}

export async function getOpenAI(text, lang, type = 0) {
  let token = window.localStorage.getItem("authToken");
  let localText = `Translate this text in ${lang} and keep new lines spacing and make response object with key 'translation': ${text}`;
  let response = null;
  if (type === 1)
    localText = `Paraphrase this text in ${lang} and keep new lines spacing and make response object with key 'translation': ${text}`;
  if (type === 2)
    localText = `Summarize this text in ${lang} and make response object with key 'translation': ${text}`;

  await fetch("https://news.energysalesdirect.com/api/v1/cockpit/openai/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      text: localText,
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      let res = JSON.parse(data.response);
      response = res.translation;
    });

  return response;
}
