import { useEffect, useRef, useState } from "react";
import { getOpenAI } from "../services/api";

const TextBox = ({ title, forID, val, handleChange, para = true, trans }) => {
  const [local, setLocal] = useState("Loading...");
  const textRef = useRef(null);

  useEffect(() => {
    async function startMe() {
      if (para && val !== "") {
        const textBox = textRef.current;
        let response = await getOpenAI(val, trans);
        handleChange({ target: { id: textBox.id, value: response } });
        setLocal(response);
      } else setLocal(val);
    }
    startMe();
  }, [trans, para]);

  async function handleSummarize() {
    let text = local;
    let res = await getOpenAI(text, trans, 2);
    const textBox = textRef.current;
    handleChange({ target: { id: textBox.id, value: res } });
    setLocal(res);
  }

  async function handleParaphrase() {
    const textarea = textRef.current;
    let aux = textarea.value;
    let text = textarea.value;
    let res = null;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    if (start !== end) {
      text = aux.substring(start, end);
    }
    res = await getOpenAI(text, trans, 1);

    if (start !== end) {
      aux = aux.substring(0, start) + " " + res + " " + aux.substring(end);
    } else {
      aux = res;
    }

    handleChange({ target: { id: textarea.id, value: aux } });
    setLocal(aux);
  }

  function localChange(e) {
    setLocal(e.target.value);
    handleChange(e);
  }

  return (
    <div className="customFlex">
      <div className="flex items-center w-full">
        <div className="labelDiv">
          <label htmlFor={forID}>{title}</label>
          {forID !== "text" ? (
            <>
              <button
                className="block custombtn"
                type="button"
                onClick={() => handleParaphrase()}
              >
                Paraphrase
              </button>
              <button
                className="block custombtn"
                type="button"
                onClick={() => handleSummarize()}
              >
                Summarize
              </button>
            </>
          ) : null}
        </div>
        <div className="inputDiv">
          <textarea
            ref={textRef}
            id={forID}
            className="customInput"
            value={local}
            onChange={(e) => localChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default TextBox;
